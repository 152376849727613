import React, { useEffect, useRef, useState } from 'react';
import { array, bool, string } from 'prop-types';
import classnames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { matomoEventTypeMap } from 'shared/utils/matomoConfig';
import s from './style.less';
import Link from '../../../vi-components/link';

const ANIMATE_INTERVAL = 6000;
let index = 1;
const updateContent = ({ setContent, setLink, list }) => {
  index = index > list.length - 1 ? 0 : index;
  const { content, link } = list[index];
  setContent(content);
  setLink(link);
  index += 1;
};

const Marquee = ({ list, hoverDisabled, className, testId }) => {
  useStyles(s);
  const [content, setContent] = useState(list[0].content);
  const [link, setLink] = useState(list[0].link);
  const [defaultAnimate, setDefaultAnimate] = useState(true);
  const intervalStamp = useRef(null);
  const timeoutStamp = useRef(null);

  const initIntervalMarquee = () => {
    intervalStamp.current = setInterval(() => {
      updateContent({ setContent, setLink, list });
    }, ANIMATE_INTERVAL);
  };

  const startIntervalMarquee = () => {
    if (hoverDisabled || list.length === 1) {
      return;
    }
    setDefaultAnimate(true);
    timeoutStamp.current = setTimeout(() => {
      const timerId = timeoutStamp.current;
      timeoutStamp.current = null;
      updateContent({ setContent, setLink, list });
      clearTimeout(timerId);

      intervalStamp.current = setInterval(() => {
        updateContent({ setContent, setLink, list });
      }, ANIMATE_INTERVAL);
    }, 2000);
  };

  const endIntervalMarquee = () => {
    if (hoverDisabled || list.length === 1) {
      return;
    }

    clearInterval(intervalStamp.current);
    clearTimeout(timeoutStamp.current);
    intervalStamp.current = null;
    timeoutStamp.current = null;
    setDefaultAnimate(false);
  };

  useEffect(() => {
    if (list.length > 1) {
      initIntervalMarquee();
    }

    return () => {
      clearInterval(intervalStamp.current);
      clearTimeout(timeoutStamp.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classnames(className, s.Notices)}
      onMouseEnter={endIntervalMarquee}
      onMouseLeave={() => {
        startIntervalMarquee();
      }}
      data-matomo={matomoEventTypeMap.main.notice}
    >
      <p className={classnames(defaultAnimate && s.Content, timeoutStamp.current && s.SlideUp)}>
        {link ? (
          <Link to={link} hasLinkStyle={false} className={s.Link} testId={testId}>
            <span>{content}</span>
            <i className={classnames('ri-arrow-right-s-line', s.ArrowIcon)} />
          </Link>
        ) : (
          <span>{content}</span>
        )}
      </p>
    </div>
  );
};

Marquee.propTypes = {
  list: array.isRequired,
  hoverDisabled: bool,
  className: string,
};

Marquee.defaultProps = {
  hoverDisabled: false,
  className: '',
};

export default Marquee;
