import React from 'react';
import Home from 'modules/home/index';
import { pageKey } from 'shared/constants/pages';

export default async () => [
  {
    path: '',
    page: pageKey.HOME,
    action: () => ({
      component: <Home />,
    }),
  },
];
