import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import style from './style.less';
import Button from '../button';

const FeatureCard = (props) => {
  useStyles(style);

  const { featureCardContent, className, featureCardHeader, testId, matomoEventType } = props;
  return (
    <div className={classNames(style.Container, className)}>
      {featureCardHeader && <h2 className={style.ContainerHeader}>{featureCardHeader}</h2>}
      <div className={classNames(style.FeatureCardContainer)}>
        <a
          href={featureCardContent.href}
          className={classNames(style.FeaturedCardImageWrapper)}
          aria-label={`${featureCardContent.headingTag} - ${featureCardContent.heading}`}
          data-matomo={matomoEventType}
        >
          <div className={classNames(style.FeaturedCardImageContainer)}>
            <img
              alt={`${featureCardContent.headingTag} - ${featureCardContent.heading}`}
              title={featureCardContent.heading}
              src={featureCardContent.imgSrc}
            />
          </div>
        </a>
        <div className={classNames(style.FeaturedCardText, 'animation', 'featured-card-text')}>
          <a
            href={featureCardContent.href}
            aria-label={`${featureCardContent.headingTag} - ${featureCardContent.heading}`}
            data-matomo={matomoEventType}
          >
            <div className={classNames(style.FeaturedCardTextTag)}>
              <span>{featureCardContent.headingTag}</span>
            </div>
            <h3 className={classNames(style.FeaturedCardTextHeading)}> {featureCardContent.heading} </h3>
            <p className={classNames(style.FeaturedCardTextDescription)}>{featureCardContent.description}</p>
          </a>
          <Button
            testId={testId}
            type="secondaryLink"
            href={featureCardContent.href}
            aria-label={`${featureCardContent.headingTag} - ${featureCardContent.heading}`}
            matomoEventType={matomoEventType}
          >
            {featureCardContent.buttonLinkTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

FeatureCard.propTypes = {
  featureCardContent: shape({
    heading: string.isRequired,
    headingTag: string.isRequired,
    imgSrc: oneOfType([object.isRequired, string.isRequired]),
    description: string.isRequired,
    href: string.isRequired,
    buttonLinkTitle: string.isRequired,
  }).isRequired,
  featureCardHeader: string,
  className: string,
  testId: string,
  matomoEventType: string,
};

FeatureCard.defaultProps = {
  featureCardHeader: undefined,
  className: '',
  testId: '',
  matomoEventType: '',
};

export default FeatureCard;
