import React from 'react';
import FullCard from 'shared/vi-components/full-card';
import img from 'shared/resources/images/diversity-equity.jpg';
import { useTranslation } from 'react-i18next';

const DiversityEquity = () => {
  const { t } = useTranslation();

  const cardContent = {
    heading: t('home.diversityEquity.heading'),
    imgSrc: img,
    description: t('home.diversityEquity.description', { returnObjects: true }),
  };
  return <FullCard cardContent={cardContent} isLeftContent />;
  // return <FullCard cardContent={cardContent} isRightContent />;
};

export default DiversityEquity;
