import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useStyles from 'isomorphic-style-loader/useStyles';
import { array } from 'prop-types';
import { testIds } from 'shared/constants/test-ids';
import s from './style.less';
import Marquee from './marquee';

const NoticeMarquee = ({ list }) => {
  useStyles(s);
  const { t } = useTranslation();

  return (
    <div className={classNames('page-container', 'col-l-12', 'content-center-n', s.Container)}>
      <div className={classNames('col-span-l-10', 'col-start-l-2', s.Content)}>
        <p className={classNames(s.Title)}>{t('home.notice.title')}</p>
        <Marquee className={s.Marquee} list={list} testId={testIds.home.noticeBody} />
      </div>
    </div>
  );
};

NoticeMarquee.prototype = {
  list: array.isRequired,
};

export default NoticeMarquee;
