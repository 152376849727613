const processData = ({ id, noticeTitle, noticeContent, updatedAt }) => ({
  id,
  noticeTitle,
  noticeContent,
  updatedAt,
});

export const getAllNotice = (state) => {
  return state.notice.list.map(processData);
};
