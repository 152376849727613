import React from 'react';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from 'react-i18next';
import poster from 'shared/resources/images/video-poster/tw-introduction.png';
import { getConfig } from 'shared/utils/config-helper';
import moment from 'moment';
import s from './style.less';

const About = () => {
  useStyles(s);
  const { t } = useTranslation();
  const year = moment().year() - 1993 - 1;
  const description = t('home.about.description').replace('{{year}}', year.toString());

  return (
    <div className="page-container col-l-12">
      <h2 className={classNames('col-span-l-10', 'col-start-l-2', s.Title)}>{t('home.about.title')}</h2>
      <div className={classNames('col-span-l-10', 'col-start-l-2', s.Description)}>{description}</div>
      <div className={classNames('col-span-l-10', 'col-start-l-2', s.Video)}>
        <video
          width="100%"
          height="100%"
          controls
          poster={poster}
          preload="none"
          autoPlay={false}
          playsInline
          src={`${getConfig('s3VideoBucketUrl')}/tw-introduction.mp4`}
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default About;
