import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { any, bool, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import s from './style.less';

const FullCard = ({ cardContent, isLeftContent, isContentVerticalCenter, className }) => {
  useStyles(s);

  return (
    <div className={classNames(className, 'col-l-2', s.Container)}>
      <div className={isLeftContent ? 'order-l-2' : ''}>
        <img alt={cardContent.heading} title={cardContent.heading} src={cardContent.imgSrc} />
      </div>
      <div
        className={classNames(
          isLeftContent ? s.CardContentLeft : s.CardContentRight,
          isLeftContent ? 'order-l-1' : '',
          isContentVerticalCenter ? s.ContentVerticalCenter : ''
        )}
      >
        <h2 className={classNames()}> {cardContent.heading} </h2>
        <div className={classNames(s.Description)}>
          {cardContent.description.map((item) => (
            <p key={item}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

FullCard.propTypes = {
  cardContent: shape({
    heading: string.isRequired,
    imgSrc: oneOfType([object, string]).isRequired,
    description: any,
  }).isRequired,
  isLeftContent: bool,
  isContentVerticalCenter: bool,
  className: string,
};

FullCard.defaultProps = {
  isLeftContent: false,
  isContentVerticalCenter: false,
  className: '',
};

export default FullCard;
