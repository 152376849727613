import React from 'react';
import img from 'shared/resources/images/overseas-program.jpg';
import { useTranslation } from 'react-i18next';
import ImageCard from 'shared/ui-components/image-card';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './style.less';

const OverseasProgram = () => {
  useStyles(s);
  const { t } = useTranslation();

  const cardContent = {
    heading: t('home.overseasProgram.heading'),
    subHeading: t('home.overseasProgram.subHeading'),
    imgSrc: img,
    description: t('home.overseasProgram.description', { returnObjects: true }),
  };
  return <ImageCard imageCardContent={cardContent} className={s.normalMargin} />;
};

export default OverseasProgram;
