import React from 'react';
import FeatureCard from 'shared/vi-components/feature-card';
import img from 'shared/resources/images/super-x.jpg';
import { useTranslation } from 'react-i18next';
import { wrapLink } from 'shared/constants/pages';
import { testIds } from 'shared/constants/test-ids';
import { matomoEventTypeMap } from 'shared/utils/matomoConfig';

const SuperXProgram = () => {
  const { t } = useTranslation();

  const featureCardContent = {
    heading: t('home.superXProgram.heading'),
    headingTag: t('home.superXProgram.headingTag'),
    imgSrc: img,
    description: t('home.superXProgram.description'),
    href: wrapLink('super-x'),
    buttonLinkTitle: t('home.superXProgram.buttonLinkTitle'),
  };
  const featureCardHeader = t('home.superXProgram.header');

  return (
    <FeatureCard
      featureCardContent={featureCardContent}
      featureCardHeader={featureCardHeader}
      testId={testIds.home.learnMore}
      matomoEventType={matomoEventTypeMap.main.superXLink}
    />
  );
};

export default SuperXProgram;
