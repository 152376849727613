// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".r2Inb {\n  overflow: hidden;\n}\n@media (max-width: 991px) {\n  .r2Inb {\n    min-height: 50px;\n  }\n}\n.r2Inb ._3sz8k {\n  animation: _2y7Yj 6s linear infinite;\n}\n.r2Inb ._3sz8k._197ol {\n  animation: _1Ghq4 2.5s linear;\n}\n.r2Inb ._32PGb {\n  font-size: 22px;\n  color: #e16a7c;\n}\n.r2Inb ._2vJNM {\n  display: flex;\n  align-items: center;\n}\n@keyframes _2y7Yj {\n  0% {\n    transform: translateY(100%);\n  }\n  5% {\n    transform: translateY(0);\n  }\n  95% {\n    transform: translateY(0);\n  }\n  100% {\n    transform: translateY(-100%);\n  }\n}\n@keyframes _1Ghq4 {\n  70% {\n    transform: translateY(0);\n  }\n  85% {\n    transform: translateY(-100%);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"Notices": "r2Inb",
	"Content": "_3sz8k",
	"marquee": "_2y7Yj",
	"SlideUp": "_197ol",
	"slide-up": "_1Ghq4",
	"ArrowIcon": "_32PGb",
	"Link": "_2vJNM"
};
module.exports = exports;
