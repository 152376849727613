import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { any, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import s from './style.less';

const ImageCard = (props) => {
  useStyles(s);

  const { imageCardContent, className } = props;
  return (
    <div className={classNames(s.Container, 'col-l-2', className)}>
      <div className={classNames(s.imageWrapper)}>
        <img alt={imageCardContent.heading} title={imageCardContent.heading} src={imageCardContent.imgSrc} />
      </div>
      <div className={classNames(s.CardContentWrapper)}>
        <div className={classNames(s.CardHeadingWrapper)}>
          <h2> {imageCardContent.heading} </h2>
          <h3> {imageCardContent.subHeading} </h3>
        </div>
        <div className={classNames(s.Description)}>
          {imageCardContent.description.map((item) => (
            <p key={item}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  imageCardContent: shape({
    heading: string.isRequired,
    subHeading: string.isRequired,
    imgSrc: oneOfType([object.isRequired, string.isRequired]),
    description: any.isRequired,
  }).isRequired,
  className: string,
};

ImageCard.defaultProps = {
  className: '',
};

export default ImageCard;
