import React, { useEffect } from 'react';
import Layout from 'shared/ui-components/layout';
import { useDispatch, useSelector } from 'react-redux';
import { flow, map, orderBy, prop, slice } from 'lodash/fp';
import * as cheerio from 'cheerio';
import CpmSubBanner from 'shared/vi-components/cpm-subheader';
import NoticeMarquee from 'shared/ui-components/notice-marquee';
import { getAllNotice } from 'shared/selectors/notice';
import { readAllNotices } from 'shared/slices/notice';
import { wrapLink } from 'shared/constants/pages';
import SuperXProgram from 'modules/home/super-x-program';
import DiversityEquity from 'modules/home/diversity-equity';
import About from 'modules/home/about';
import { recoverBodyScroll } from 'shared/utils/dom-helper';
import Banner from 'shared/vi-components/banner';
import { useTranslation } from 'react-i18next';
import useStyles from 'isomorphic-style-loader/useStyles';
import { matomoEventTypeMap } from 'shared/utils/matomoConfig';
import OverseasProgram from 'modules/home/overseas-program';
import s from './style.less';

export default () => {
  useStyles(s);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const notices = useSelector((state) => {
    return flow(
      getAllNotice,
      orderBy(prop('updatedAt'), ['desc']),
      slice(0, 10),
      map(({ noticeTitle, id }) => ({
        content: cheerio.load(noticeTitle).text(),
        link: wrapLink(`notice#${id}`),
      }))
    )(state);
  });

  useEffect(() => {
    recoverBodyScroll();

    async function readAll() {
      await dispatch(readAllNotices());
    }

    readAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bannerContent = {
    buttonTitle: t('home.subBanner.button'),
    buttonHref: wrapLink('recruitment/grad'),
    bannerTitle: t('home.subBanner.title'),
    buttonMatomoEventType: matomoEventTypeMap.main.recruitment,
  };

  return (
    <Layout>
      <Banner
        titles={t('home.banner.title', { returnObjects: true })}
        link={{ text: t('home.banner.link'), url: wrapLink('about'), matomoEventType: matomoEventTypeMap.main.aboutUs }}
        className={s.Banner}
      />
      <CpmSubBanner bannerContent={bannerContent} />
      {!!notices.length && <NoticeMarquee list={notices} />}
      <About />
      <SuperXProgram />
      <DiversityEquity />
      <OverseasProgram />
    </Layout>
  );
};
